const homepageSlider = function(){
	const homeScroller = document.getElementById("mainSlider");
	const pauseIcon = 
		"<svg class='textDkGray' width='30' height='30' focusable='false'><title>Pause Animation</title> <use xlink:href='#svgIcon-pause'></use> </svg>";
	const playIcon = 
		"<svg class='textDkGray rotate90' width='30' height='30' focusable='false'><title>Pause Animation</title> <use xlink:href='#svgIcon-arrowSolid'></use> </svg>";
	const animationBtn = document.getElementById("pausePlayBtn");
	let homeSlider;
	
	const init = () => {
		homeSlider = tns({ 
			container: ".homeSlider",
			autoplay: true,
			speed: 1000,
			autoplayTimeout: 5500,
			lazyload: false, 
			controls: false,
			autoplayHoverPause: true,
			autoplayPosition: "top",
			autoplayButton: "#pausePlayBtn",
			autoplayText: [
			    playIcon,
			    pauseIcon
			]
		});
		homeScroller.classList.remove("invisible"); // slider is set up
		setTabindex();
		homeSlider.events.on('transitionEnd', setTabindex);
		animationBtn.addEventListener("click", toggleAriaPressed);
	};
	
	const toggleAriaPressed = (e) =>{
		e.preventDefault();
		if (animationBtn.getAttribute("aria-pressed") == "false") animationBtn.setAttribute("aria-pressed", "true");
		else animationBtn.setAttribute("aria-pressed", "false");
	};
	
	const setTabindex = () =>{
		// set the active slide button to tabindex 0 and the rest to -1 so they cannot be tabbed to
		const slides = [].slice.call(homeScroller.querySelectorAll(".tns-item"));
		for(let i = 0; i < slides.length; i++){
			if(slides[i].querySelector("a")) slides[i].querySelector("a").setAttribute("tabindex", "-1");
		}
		const activeSlide = homeScroller.querySelector(".tns-slide-active");
		if(activeSlide && activeSlide.querySelector("a")) activeSlide.querySelector("a").setAttribute("tabindex", "0");
	};
	
	return{
		Init: init
	};
};